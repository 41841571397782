import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import exportValue from "../../apiconfig";
import { requestForToken, onMessageListener } from '../../firebase';

if (!localStorage.getItem('sellsbuy_firebaseToken')) {
  requestForToken().then((data) => {
   // console.log("fire token =>> ", data);
    if (data.status && data.token != "") {
      localStorage.setItem('sellsbuy_firebaseToken', data.token);
    }
  });
}

const Login = () => {
  const navigate = useNavigate();
  ReactSession.setStoreType("localStorage");
  //console.log(localStorage.getItem('sellsbuy_loggedIn'));
  if (localStorage.getItem('sellsbuy_loggedIn')) {
    //navigate('/chat-box')
  }


  // console.log("value- url ------->  ",value);
  const [state, setState] = useState({ email: "", password: "",userType:"C" })
  useEffect(() => {
    //console.log("useEffect ------->  ");
    if (localStorage.getItem('sellsbuy_loggedIn')) {
      navigate('/chat-box')
    }
    //   MessageUrlView()
  }, [])

  const submitForm = (e) => {
    //console.log("e url =>",e);
    e.preventDefault()
    // console.log("state url =>",state);

    var res = state.email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    if (res != null) {
      let full_api = `https://backend.sellsbuy.com/messanger/login`;
      let sendData = { username: state.email, password: state.password, type: state.userType };
      if (localStorage.getItem('sellsbuy_firebaseToken')) {
        sendData.token = localStorage.getItem('sellsbuy_firebaseToken');
      }
      Axios.post(full_api, sendData, { headers: { "Content-Type": "application/json" } }).then(res => {
      // console.log("login ", typeof(res.data.data));
        if (res.data.status == 200 && typeof(res.data.data) == "object") {
          //res.data.data
          let userDetail = res.data.data;
          // let userDetail = {
          //   user_id:data.uid,
          //   email:data.email,
          //   mobile:data.mobile,
          //   profile_image:(data.image) ? data.image : "",
          //   full_name:data.name
          // }

          ReactSession.set("sellsbuy_loggedIns", JSON.stringify(userDetail));
          localStorage.setItem('sellsbuy_loggedIn', JSON.stringify(userDetail));
          let full_api2 = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user/new_user_check`;
          Axios.post(full_api2, userDetail, { headers: exportValue.headers }).then(res => {

          })

          navigate('/chat-box')
        }else {
           toast.configure()
            toast(res.data.data)
        }

      }).catch((e) => {
        // toast.configure()
        //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
      // getLinkPreview(msg).then((data) => {
      //     console.log("datas->>     ", data)
      //     let linkMsg = {};
      //     linkMsg['url'] = msg;
      //     linkMsg['urlTitle'] = (data.title) ? data.title : "";
      //     linkMsg['urlDescription'] = (data.description) ? data.description : ""; 
      //     linkMsg['urlFavicons'] =  (data.favicons && data.favicons.length > 0) ? data.favicons[0] : ""; 

      //     linkMsg['urlImg'] = (data.images && data.images.length > 0) ? data.images[0] : ""; 
      //     setUrlMsg(linkMsg)
      //         console.log("datas->>     ", data)                
      //         console.log("msg->>     ", linkMsg)
      //         //data.value = linkMsg;
      //         return linkMsg;

      // });
    } else {
      //console.log("----not a valid email:   ");
      toast.configure()
      toast("Not a valid email-id")
    }
  }

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  }

 const  userSelect = (e) => {
  //console.log("e=>  ", e);
 }

  return (

    <div>
      <section className="vh-100 " style={{ backgroundColor: "#999999" }}>
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-12 col-xl-11">
              <div className="card text-black" style={{ borderRadius: "25px" }}>
                <div className="card-body p-md-5">
                  <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">

                      <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Log in </p>

                      <form className="mx-1 mx-md-4" onSubmit={(e) => submitForm(e)}>



                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input type="email" id="form3Example3c" name="email" className="form-control" placeholder='Enter your Email' onChange={(e) => handleChange(e)} />
                            {/* <label className="form-label" for="form3Example3c">Your Email</label> */}
                          </div>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input type="password" id="form3Example4c" name="password" className="form-control" placeholder='Enter your password' onChange={(e) => handleChange(e)} />
                            {/* <label className="form-label" for="form3Example4c">Password</label> */}
                          </div>
                        </div>
                        <div className="form-check justify-content-center mb-2">
                          <div className="row">                         
                             <div className="col-4">
                            <input className="form-check-input me-2" type="radio" value="C" id="form2Example3c" checked={(state.userType == "C") ? "checked" : "" } name="userType" onChange={(e)=>handleChange(e)}/>
                            <label className="form-check-label" for="form2Example3">
                              Buyer
                            </label>
                          </div>
                            <div className="col-4">
                              <input className="form-check-input me-2" type="radio" value="A" checked={(state.userType == "A") ? "checked" : "" } id="form2Example3c" name="userType" onChange={(e)=>handleChange(e)}/>
                              <label className="form-check-label" for="form2Example3">
                                Seller
                              </label>
                            </div>
                          </div>

                        </div>



                        <div className="form-check justify-content-center mb-5">
                          <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3c" />
                          <label className="form-check-label" for="form2Example3">
                            Remember me
                          </label>
                        </div>

                        <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                          <button type="submit" className="btn btn-primary btn-lg">Login</button>


                        </div>
                        <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                          <a href="https://www.sellsbuy.com" target={"_blank"}>Create new account</a>
                        </div>

                      </form>

                    </div>
                    <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">

                      <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                        className="img-fluid" alt="Sample image" />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )

}

export default Login;