import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import '../node_modules/font-awesome/css/font-awesome.min.css';

import './App.css';
import Index from './views/Index';


import Registration from "./views/Registration";

import Chat_box from './views/chat-box/Chat_box';
import Login from './views/login/Login';


function App() {
  return (
    <>
    <CookiesProvider>
        
          <Router>
            <Routes>
              <Route exact path='/' element={<Chat_box />} />
              <Route exact path='/login' element={<Login />} />
              <Route exact path='/chat-box/:other_id' element={<Chat_box />} />
              <Route exact path='/chat-box' element={<Chat_box />} />
              {/* <Route exact path='/registration' element={<Registration />} /> */}

            </Routes>
          </Router>
       
      </CookiesProvider>
    </>
  );
}

export default App;
