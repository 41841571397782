import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAZWBA1Mn4zmNr1ZYVF1bAnKF8k7Emde-8",
    authDomain: "mojo-messenger2022.firebaseapp.com",
    projectId: "mojo-messenger2022",
    storageBucket: "mojo-messenger2022.appspot.com",
    messagingSenderId: "243235794135",
    appId: "1:243235794135:web:efa0bb3b42b51accb2d492",
    measurementId: "G-0MEG204BDS"
};
initializeApp(firebaseConfig);
const messaging = getMessaging();
//console.log("support   ",messaging);
export const requestForToken = () => {
    return  new Promise ((resolve)=>{
    getToken(messaging, { vapidKey: "BMm7PVroy4jHsxQXKUjeB7qIxK7hoL3iWdHrJAqsY0fv_6v27wErRdN9u1rN-CxPj-4SCHKV2-hcpzsbTPGDI34" }).then((currentToken) => {
        if (currentToken) {
            console.log("currentToken -->   ", currentToken);
            resolve({token:currentToken,status:true});
        } else {
            console.log("no registration");
            resolve({token:"",status:false});
        }
    }).catch((err) => {
        console.log("Error:->  ", err);
        resolve({token:"",status:false});
    })
    })
}

export const onMessageListener = () => {
    return  new Promise ((resolve)=>{
        onMessage(messaging,(payload)=>{
            console.log("payload  --  ", payload );
            resolve(payload)
        })
    })
}
//export default messaging;